// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jetpack: {
    4: "0xf65a04afa6493c76cb6f8a6172be2d04933492ac",
    1: "0xd0f0c40fcd1598721567f140ebf8af436e7b97cf",
    56:"",
    97: "",
  },
  hoverboard: {
    4: '0x400d23F7E8595a6f8a9c672B44BF7873D3178FbD',
    1: '0xeDa3b617646B5fc8C9c696e0356390128cE900F8',
    56: '',
    97: '',
  },
  hoverboardDistributor: {
    4: '0xA11B0Da01e0b579248e50D6BD9454852dC1cd6c3',
    1: '0x053513b3c1482Da58725924Ab168B6358dda66Fc',
    56: '',
    97: '',
  },
  multiCall: {
    1: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
    4: '0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821',
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
};
