import multicall from 'utils/multicall'
import { getHoverboardAddress, getHoverboardDistributorAddress } from 'utils/addressHelpers'
import HoverboardDistributionAbi from 'config/abi/HoverboardDistribution.json'
import { MintState } from 'state/types';

export const fetchContractInfo = async (): Promise<MintState> => {
    const calls = [
        {
            address: getHoverboardAddress(),
            name: 'totalSupply',
        },
        {
            address: getHoverboardDistributorAddress(),
            name: 'PRE_SALE_START_TIME',
        },
        {
            address: getHoverboardDistributorAddress(),
            name: 'PUBLIC_SALE_TIME_START',
        },
    ];

    const contractInfoCallsResult = await multicall(HoverboardDistributionAbi, calls)
    return {
        maxSupply: 6666,
        currentSupply: contractInfoCallsResult[0][0].toNumber(),
        preSaleStartTime: contractInfoCallsResult[1][0].toNumber(),
        publicSaleStartTime: contractInfoCallsResult[2][0].toNumber(),
    }
}


export const setCurrentSupply = async (web3) => {
    const calls = [
        {
            address: getHoverboardAddress(),
            name: 'totalSupply',
        },
    ];

    const contractInfoCallsResult = await multicall(HoverboardDistributionAbi, calls, web3)

    return contractInfoCallsResult[0][0].toNumber()

}