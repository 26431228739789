import { useAppDispatch } from 'state'
import { State } from './types'
import { useSelector } from 'react-redux'
import { fetchMintInfo } from './mint'


export const useFetchPublicData = () => {
  const dispatch = useAppDispatch()
  dispatch(fetchMintInfo())
} // end of use Fetch Public Data

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}


// MINT

export const useMintInfo = (account=null) => {
  return useSelector((state: State) => state.mint)
}

export const useMintCurrentSupply = () => {
  return useSelector((state: State) => state.mint.currentSupply)
}
