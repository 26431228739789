import { useState, useEffect } from 'react'

export const useCountDownTime = (timeToGoLiveMillis: number) => {
  const [countDownMillis, setCountDownMillis] = useState(new Date(timeToGoLiveMillis).getTime() - new Date().getTime())

  useEffect(() => {
    const tick = () => {
      setCountDownMillis(timeToGoLiveMillis - new Date().getTime())
    }

    const timerID = setInterval(() => tick(), 1000)

    return () => clearInterval(timerID)
  }, [timeToGoLiveMillis])

  return countDownMillis
}

export default useCountDownTime