import React, { lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.scss'
import './fonts/NostraStream/style.css'
import './fonts/gosha-sans/style.css'

import GlobalStyle from './style/Global'
import PageLoader from './components/PageLoader'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import Web3ReactManager from 'components/Web3ReactManager'
import { useFetchPublicData } from 'state/hooks'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Header from 'components/Header'

const Home = lazy(() => import('./views/Home'))
const App: React.FC = () => {

  useFetchPublicData()

  return (
    <BrowserRouter >
      <GlobalStyle />
      <ToastContainer />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Web3ReactManager>
          <Switch>
            <Route path="/" exact>
            <Header />
              <Home />
            </Route>
          </Switch>
        </Web3ReactManager>
      </SuspenseWithChunkError>

    </BrowserRouter>
  );
}

export default App;
