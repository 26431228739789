import useCountDownTime from 'hooks/useCountDownTimer';
import React from 'react'
import { useMintInfo } from 'state/hooks';

const Header: React.FC = () => {

  const mintState = useMintInfo();

  const isPreSaleStarted = (Date.now() / 1000) >= mintState.preSaleStartTime;
  const isPublicSaleStarted =  (Date.now() / 1000) >= mintState.publicSaleStartTime;

  let message = ''
  let milliSecondsForCounter = -1;

  if (!isPreSaleStarted) {
    message = 'PRE SALE STARTING IN'
    milliSecondsForCounter = new Date(mintState.preSaleStartTime * 1000).getTime()
  } else if (isPreSaleStarted && !isPublicSaleStarted) {
    message = 'PRE SALE ENDING IN'
    milliSecondsForCounter = new Date(mintState.publicSaleStartTime * 1000).getTime()
  } else {
    message = 'PUBLIC SALE STARTED'
  }

  const countDownMillis = useCountDownTime(milliSecondsForCounter);

  function msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
      days = (duration / (1000 * 60 * 60 * 24)).toFixed(0);

    const hoursStr = (hours < 10) ? 0 + hours.toString() : hours;
    const minutesStr = (minutes < 10) ? 0 + minutes.toString() : minutes;
    const secondsStr = (seconds < 10) ? 0 + seconds.toString() : seconds;
    
    if ( Number(days) < 0 || hours < 0 || minutes < 0 || seconds < 0 ) {
      return ''
    }
    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }
  return (
    <div style={{
      width: '100%',
    }}>
      <p style={{
        'fontFamily': "Gosha Sans Regular",
        'color': 'white',
        'textAlign': 'center',
        'marginTop': '50px',   
        position: 'absolute',
        'top': '0',
        'right': '0',
        'left': '0'
      }}> {
          `${message} ${milliSecondsForCounter !== -1 ? msToTime(countDownMillis): ''}`
        } </p>
    </div>
  )
}


export default Header
