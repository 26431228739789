/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MintState } from '../types'
import { fetchContractInfo, setCurrentSupply } from './fetchContractInfo';

const initialState: MintState = { 
  maxSupply: 1111, 
  currentSupply: 0,
  preSaleStartTime: 1,
  publicSaleStartTime: 1,
}

export const mintSlice = createSlice({
  name: 'Mint',
  initialState,
  reducers: {
    initializeWithContractInfoReducer: (state, action) => {
      const contractMintState: MintState = action.payload;
      Object.assign(state, contractMintState);
    },
    setCurrentSupplyReducer: (state, action: PayloadAction<number>) => {
      state.currentSupply = action.payload
    },
    setCurrentUserInfo: (state, action) => {
      state.userData = action.payload
    },
  },
})

// Actions
export const { initializeWithContractInfoReducer, setCurrentSupplyReducer, setCurrentUserInfo } = mintSlice.actions

// Thunks
export const fetchMintInfo = () => async (dispatch) => {
  const contractMintInfo = await fetchContractInfo()
  dispatch(initializeWithContractInfoReducer(contractMintInfo))
}

export const fetchMintSupply = (web3) => async (dispatch) => {
  const contractMintInfo = await setCurrentSupply(web3)
  dispatch(setCurrentSupplyReducer(contractMintInfo))
}


export default mintSlice.reducer

